.formTable .ant-table-tbody > tr > td{
  padding: 0 !important;
}

.addressFormLabel > .ant-form-item-row {
  height: 48px
}

.tableTitle .tableAstrisk {
  color: #ff4d4f;
}