* { 
	box-sizing: border-box;
}

html, body {
	min-height: 100%;
	background: #fff;
	color: #000;
}

body {
	font-size: 1.4rem;
	text-rendering: optimizeLegibility;
  margin: 0;
}

#root {
  height: 100%;
}